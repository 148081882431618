import Navigation from "../Navigation";
import "./index.css";
import ImageDisplay from "../ImageDisplay";

function ComputerScience() {
  return (
    <div className="wholeThing">
      <div style={{ position: "sticky", top: "0", zIndex: "100" }}>
        <Navigation />
      </div>
      <div className="designBody">
        <div className="bigTitle">
          <h1>Computer Science</h1>
        </div>
        <br></br>
        <ImageDisplay
          filePath="/images/screenshots/mainWebsite.JPG"
          title="Personal Website (This One)"
          description="This site is made from scratch! (I also made the logo.) I first designed this website in Figma and then
            utilized Javascript, React, and Bootstrap to code it. Feel free to navigate around the website to see each
            page :)"
          moreFiles={[]}
        />
        <br></br>
        <ImageDisplay
          filePath="/images/screenshots/jrivia.JPG"
          title="Jrivia"
          description="A fun trivia website made in collaboration with Jared Rudnicki using Javascript, React, and Node. Supports multiple types of users,
            such as moderators, general users, and guests. Also supports manually creating and answering trivia questions, as 
            well as generating random ones from an external API."
          moreFiles={["/images/screenshots/jrivia1.JPG", "/images/screenshots/jrivia2.JPG"]}
          links=<p className="links">
            See the deployed website{" "}
            <a href="https://jrivia.netlify.app/">here!</a>
            <br></br>
            You can also check out the{" "}
            <a href="https://github.com/jessiemmm/jrivia">main repo</a> and{" "}
            <a href="https://github.com/jaredrudnicki/jrivia-node">node repo.</a>
          </p>
        />
        <br></br>
        <ImageDisplay
          filePath="/images/screenshots/graphics1.png"
          title="2.5D Game"
          description="Coded in C++ and utilizes OpenGL. Using some starter code, implemented a basic skybox and animation that corresponds to keys being
            pressed, as well as a capability to change the platform texture. Also implemented camera
            movement to follow the character, zoom in/out of the scene and use the mouse to look around the environment."
          moreFiles={[
            "/images/screenshots/graphics2.png",
            "/images/screenshots/screenshot3.png",
          ]}
        />
        <br></br>
      </div>
    </div>
  );
}

export default ComputerScience;
